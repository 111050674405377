import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import parse from "html-react-parser";
import { HashLink as Link } from 'react-router-hash-link';

const text1 = "TRADING AND INVESTEMENT GROUP"
const text2 = "VIEWMORE";

class PageBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  render() {
    const storyStyle = {
      background: "linear-gradient( rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0) ), url('" + this.props.banner.background + "') no-repeat center center",
      backgroundSize: "cover"
    };
    const isMobile = this.state.width <= 500;
    let style;
    if (isMobile) {
      style="shape-container d-flex align-items-center mt-7"
    } else {
      style="shape-container d-flex align-items-center mt-xl"
    }
    console.log(this.state.style);
    return (
      <>
        <section
          className="section section-lg section-banner section-shaped"
          style={storyStyle}
        >
          <Container className={style}>
            <div className="col px-0 mx-lg-md">
              <Row>
                <Col lg="4">
                  <p className="display-3">
                    <span className="home-banner-white">TRADING AND </span>
                    <span className="home-banner-blue">INVESTMENT</span>
                    <span className="home-banner-white">GROUP</span>
                  </p>
                  <div className="btn-wrapper">
                    <Button
                      className="mb-3 mb-sm-0 button"
                      size="sm"
                      color="info"
                    >
                      <AnchorLink className="nav-link" href="#whoweare">
                        <span className="btn-inner--text text-white">VIEW MORE</span>
                      </AnchorLink>
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </>
    );
  }
}

export default PageBanner;
