import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import NavbarBrandCreate from "assets/js/NarbarBrandCreate";
import DemoNavbarTL from "./DemoNavbarTL"

  const aboutus = [{ id:"1",
                    title:"HOME",
                    },

                    {id:"2",
                    title:"WHO WE ARE",
                    },

                    {id:"3",
                    title:"OUR BUSINESS",
                    }]


class DemoNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    }
  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  render() {
    const { width } = this.state;
    const isMobile = width <= 500;
    // if (isMobile) {
    //   return (
    //     <>
    //       <header className="header-global">
    //         <DemoNavbarMB mainScreen={this.props.mainScreen}/>
    //       </header>
    //     </>
    //   );
    // }else{
      return (
        <>
          <header className="header-global">
            <DemoNavbarTL mainScreen={this.props.mainScreen}/>
          </header>
        </>
      )
    }
  }
// }

export default DemoNavbar;
