import React from "react";

// reactstrap components
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  Button
} from "reactstrap";

import parse from "html-react-parser";

class Whoweare extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  render() {
    const isMobile = this.state.width <= 500;
    let style;
    if (isMobile) {
      style = "row-grid align-items-top my-sm"
    } else {
      style = "row-grid align-items-top my-lg"
    }
    return (
      <>
        <main id="whoweare">
          <section className="section-nucleo-icons">
            <Row className={style}>
              <Col md="4">
                <span className="home-title-body-black">WHO </span><br /><span className="home-title-body-blue">WE ARE</span>
                <br /><br /><img src='underbar.png' />
              </Col>
              <Col md="8">
                <div>
                  <p className="home-child-title-body">{parse(this.props.content.childTitle)}</p>
                  <p className="description-Normal">
                    {this.props.content.content1}
                  </p>
                  <p className="description-Normal">
                    {this.props.content.content2}
                  </p>
                  <p className="description-Normal">
                    {this.props.content.content3}
                  </p>
                </div>
              </Col>
            </Row>
          </section>
        </main>
      </>
    );
  }
}
export default Whoweare;

