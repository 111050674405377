import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  CardImg,
  Button,
  Container
} from "reactstrap";

const Body = [{
  title: "F&B",
  desc: "HMG has grown to become the leading food business leader for a diverse range of food markets, such as food ingredients, processed food, private labeled food, bakery, confectionery, beverages, and even dining businesses."
}];

class Whatwefocus extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  contentChange = () => {
    this.props.content.map((value) => {
      return (
        <Row className="row-grid align-items-top">
          <Col md="6" className="pr-5">
            <div>
              <p className="home-child-title-body" id="fb_page">{value.titleONE}</p>
              <h6 className="description-Normal">
                {value.contentONE}
              </h6>
            </div>
          </Col>
          <Col md="6" className="pl-5">
            <div>
              <p className="home-child-title-body" id="fb_page">{value.titleTWO}</p>
              <h6 className="description-Normal">
                {value.contentTWO}
              </h6>
            </div>
          </Col>
        </Row>
      );
    })
  }

  render() {
    const isMobile = this.state.width <= 500;
    let right, left, top;
    if (isMobile) {
      right = "";
      left = "";
      top = "row-grid align-items-top mt-sm";
    } else {
      right = "pr-5"
      left = "pl-5"
      top = "row-grid align-items-top mt-lg"
    }
    return (
      <>
        <section className="section-nucleo-icons" id="business_page">
          <Row className={top}>
            <Col md="4">
              <span className="home-title-body-black">WHAT</span><br /><span className="home-title-body-blue">WE FOCUS</span>
              <br /><br /><img src='underbar.png' />
            </Col>
          </Row>
        </section>
        <Container className="mt-4 py-lg-sm d-flex ">
          <div className="col px-0 mx-lg-md">
            {
              this.props.content.map((value) => {
                return (
                  <Row className="row-grid align-items-top">
                    <Col md="6" className={right}>
                      <div>
                        <p className="home-child-title-body" id="fb_page">{value.titleONE}</p>
                        <h6 className="description-Normal">
                          {value.contentONE}
                        </h6>
                      </div>
                    </Col>
                    <Col md="6" className={left}>
                      <div>
                        <p className="home-child-title-body" id="fb_page">{value.titleTWO}</p>
                        <h6 className="description-Normal">
                          {value.contentTWO}
                        </h6>
                      </div>
                    </Col>
                  </Row>
                );
              })
            }
          </div>
        </Container>
      </>
    );
  }
}

export default Whatwefocus;
