import React from "react";
import { Container, Row, Col, Card, CardImg } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/luxury.png",
    Title: "OUR BUSINESS",
    childTitle: "LUXURY <br/> FASHION <br/> BEAUTY"
}

const title = "GLOBAL DISTRIBUTION & ACCELERATION"

const content = [
    {
        value: `The combination of today’s volatility, changing growth areas, and new technologies disrupting the global economy have given way to a more connected and discernible global luxury, fashion, and beauty consumers ever than before. As the pace of industry change accelerates, having innovative and sustainable business models for luxury, fashion, and beauty are increasingly important to meet its unique characteristics: fast-moving product cycles, durability, heritage, globalization of trends and styles.`
    },
    {
        value: `Our clients range from medium-size companies to industry leaders—spanning across producers and brands, fashion and beauty manufacturers, vertical fashion and beauty wholesaler & retailers, multi brand retailers, department stores, luxury-goods companies, and global online platforms where we handle global online and offline distribution using our wide networks of sales channels, provide eCommerce Platform Acceleration, and Digital Transformation Consultancy of Luxury, Fashion & Beauty.`
    },
    {
        value: `Our global team of experts includes former product, merchandising, sales, and supply-chain managers from renowned fashion, beauty and luxury companies, have brought our expertise and industry insights to more than 1,000 fashion, beauty, and luxury projects over the past years.`
    },
    {
        value: `We advise across all functions along the value chain—consumer insights, value proposition, company strategy, product creation, supply chain, channels, stores, and online. Some specific examples include the following:`
    }]

const link = [
    {
        value: 'Sourcing & Distribution'
    },
    {
        value: 'International Growth'
    },
    {
        value: 'Omni-Channel Supply Chain'
    },
    {
        value: 'Global Partnerships'
    },
    {
        value: 'Brand Management'
    },
    {
        value: 'Digital Strategy'
    },
    {
        value: 'Content Creations'
    },
    {
        value: 'Pricing and Promotions'
    },
    {
        value: 'Consumer Experience'
    },
    {
        value: 'Restructuring, Merger & Acquisition'
    },
]

const image =
{
    img1: "content/luxury1.png",
    img2: "content/luxury2.png",
}


class Luxury extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    imageChange = () => {
        const isMobile = this.state.width <= 500;
        if (isMobile) {
            return (
                <Row className="justify-content-center">
                    <Col className="mb-lg-0 px-0" md="12">
                        <CardImg src="content/luxury1.png" alt="Card image cap" /></Col>
                    <Col className="mb-lg-0 px-0" md="12">
                        <CardImg src="content/luxury2.png" alt="Card image cap" /></Col>
                </Row>
            );
        } else {
            return (
                <Container className="pb-md d-flex">
                    <CardImg src="content/luxury3.png" alt="Card image cap" />
                </Container>
            );
        }
    }
    render() {
        const isMobile = this.state.width <= 500;
        let style;
        if (isMobile) {
            style = "py-sm section-components"
        } else {
            style = "section section-components"
        }
        return (
            <>
                <DemoNavbar />
                <main className="luxury" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className={style}>
                        <Container>
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">LUXURY,<br /> FASHION AND <br />BEAUTY </span><br /><span className="home-title-body-blue">DISTRIBUTION</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <p className="home-child-title-body" id="fb_page">
                                            {title}
                                        </p>
                                        {
                                            content.map(value => {
                                                return (
                                                    <p className="description-Normal">
                                                        {value.value}
                                                    </p>
                                                );
                                            })
                                        }
                                        <ul className="ul-create">
                                            {
                                                link.map(value => {
                                                    return (
                                                        <li className="link-li">{value.value}</li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section>
                </main>
                {this.imageChange()}
                <CardsFooter />
            </>
        );
    }
}

export default Luxury;