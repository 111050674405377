import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  CardImg,
  Button,
  Container,
} from "reactstrap";

const Body = [
  {
    title: "F&B",
    desc:
      "HMG has grown to become the leading food business leader for a diverse range of food markets, such as food ingredients, processed food, private labeled food, bakery, confectionery, beverages, and even dining businesses.",
  },
];

class ContactUS extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  styleChange = () => {
    const isMobile = this.state.width <= 500;
    const contactStyle = {
      background:
        "linear-gradient( rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0.8) ), url('" +
        this.props.content.background +
        "') no-repeat center center ",
      backgroundSize: "cover",
    };
    if (isMobile) {
      return (
        <section
          className="section-created-mobile section-contactus section-shaped"
          style={contactStyle}
        >
          <Container className="d-flex pd-0">
            <div className="col">
              <Row className="justify-content-between">
                <div className="mr-sm">
                  <p className="home-title-body-white mb-1">CONTACT </p>
                  <p className="home-title-body-blue mb-1">US</p>
                  <img src="underbar.png" />
                  <div>
                    <div className="pt-4">
                      <span className="description-Normal-footer">
                        SINGAPORE HEADQUARTER
                      </span>
                      <br />
                      <span className="description-Normal-footer text-white">
                        HM GLOBAL HOLDINGS PTE. LTD.
                      </span>
                    </div>
                  </div>
                  <div className="my-4">
                    <span className="description-Normal-footer">
                      AFFILITATES
                    </span>
                    <br />



                    <Row className="row-grid-contact align-items-top">
                        <Col md="6" className="description-Small-footer text-white">
                            PT. LEO JAYA GROUP   
                        </Col>
                        <Col md="6" className="description-Small-footer text-white">
                            PT. HMY ESL GLOBAL
                        </Col>
                    </Row>
                    <Row className="row-grid-contact align-items-top">
                        <Col md="6" className="description-Small-footer text-white">
                          PT. RIBU JAYA MANDIRI   
                        </Col>
                        <Col md="6" className="description-Small-footer text-white">
                            HUMAN NETWORKS KOREA
                        </Col>
                    </Row>
                    <Row className="row-grid-contact align-items-top">
                        <Col md="6" className="description-Small-footer text-white">
                        HUMAN NETWORKS INDONESIA   
                        </Col>
                        <Col md="6" className="description-Small-footer text-white">
                            PT. SMS INDONESIA
                        </Col>
                    </Row>
                    
                  </div>
                  <p className="description-Normal-footer text-white">
                     Singapore | Indonesia | Vietnam | Korea | USA | China
                  </p>
                </div>
                <div
                  className="shape-container d-flex align-items-center"
                  style={{ marginTop: "5%" }}
                >
                  <div>
                    <p className="contactus-email text-white">
                      E-Mail: info@hmssg.co
                    </p>
                  </div>
                </div>
              </Row>
            </div>
          </Container>
        </section>
      );
    } else {
      return (
        <section
          className="section section-contactus section-shaped mt-lg"
          style={contactStyle}
        >
          <Container className="d-flex">
            <div className="col px-0">
              <Row className="row-grid align-items-top justify-content-between">
                <Col md="6" className="pt-2">
                  <div className="contener-content">
                    <span className="home-title-body-white">CONTACT</span>
                    <br />
                    <span className="home-title-body-blue">US</span>
                    <br />
                    <br />
                    <img src="underbar.png" />
                    <br />
                    <br />
                    <span className="contactus-email text-white">
                      E-Mail: info@hmssg.co
                    </span>
                  </div>
                </Col>
                <Col md="1">
                  <div className="right-line" />
                </Col>
                <Col md="5" className="py-sm">
                  <div>







                    <span className="description-Normal-footer">
                      SINGAPORE HEADQUARTER
                    </span>
                    <br />
                    <span className="description-Normal-footer text-white">
                      HM GLOBAL HOLDINGS PTE. LTD.
                    </span>
                  </div>

                  <div className="my-4">
                    <span className="description-Normal-footer">
                      AFFILITATES
                    </span>

                    <Row className="row-grid-contact align-items-top">
                        <Col md="6" className="description-Small-footer text-white">
                            PT. LEO JAYA GROUP   
                        </Col>
                        <Col md="6" className="description-Small-footer text-white">
                            PT. HMY ESL GLOBAL
                        </Col>
                    </Row>
                    <Row className="row-grid-contact align-items-top">
                        <Col md="6" className="description-Small-footer text-white">
                          PT. RIBU JAYA MANDIRI   
                        </Col>
                        <Col md="6" className="description-Small-footer text-white">
                            HUMAN NETWORKS KOREA
                        </Col>
                    </Row>
                    <Row className="row-grid-contact align-items-top">
                        <Col md="6" className="description-Small-footer text-white">
                        HUMAN NETWORKS INDONESIA   
                        </Col>
                        <Col md="6" className="description-Small-footer text-white">
                            PT. SMS INDONESIA
                        </Col>
                    </Row>

                    
                  </div>

                  <div>
                    <span className="description-Normal-footer text-white">
                    Singapore | Indonesia | Vietnam | Korea | USA | China
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      );
    }
  };
  render() {
    return (
      <>
        <main id="contact-us">{this.styleChange()}</main>
      </>
    );
  }
}

export default ContactUS;
