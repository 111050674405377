import React from "react";

// reactstrap components
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardImg
} from "reactstrap";



class WhoweareMobile extends React.Component {
  render() {
    return (
      <>
        <section className="whoweare_page" id="whoweare_page">
            <Container className="container-lg">
              <Row className="align-items-center justify-content-center">
                <Col className="text-center contener-content">
                  <p><span className="title-didot-black">WHO</span><span className="title-didot-grey"> WE ARE</span></p>
                  <h6 className="description-Italic">'A GLOBAL GROUP CREATING A BETTER LIFE FOR ALL MANKIND'</h6>
                </Col>
              </Row>
              <Row className="row-grid">
                <Col>
                  <Card className="bg-default border-0">
                      <CardImg
                        alt="..."
                        src={require("assets/img/brand/main/whoweare.png")}
                        top
                      />
                  </Card>
                </Col>
              </Row>
              <Row className="align-items-center justify-content-center mt-4">
                <Col className="text-center contener-content mt-sm-5">
                  <h6 className="description-Italic">HM GLOBAL (HMG) HAS CONSTANTLY GROWN BY FOCUSING ON OUR 
                    CORE COMPETENCIES IN THE FOOD DISTRIBUTION,GLOBAL SOURCING, REAL ESTATE,
                    AND M&A INVESTMENT BUSINESSES TO DEVELOP NEW GROWTH ENGINES AND EXPANDING 
                    OUR GLOBAL BUSINESSES</h6>
                  <h6 className="description-Italic mt-sm">IN ADDITION TO NOTABLE SIZE INCREASE IN GOLBAL EXPANSION,
                  HMG HAS BEEN TRANSFORMED INTO ONE OF ASIA’S REPRESENTATIVE INVESTMENT COMPANIES.
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
      </>
    );
  }
}

export default WhoweareMobile;

