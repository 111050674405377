import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  CardImg,
  Button
} from "reactstrap";

const Body = [{   
  title:"F&B",
  desc:"HMG has grown to become the leading food business leader for a diverse range of food markets, such as food ingredients, processed food, private labeled food, bakery, confectionery, beverages, and even dining businesses."
  },
  
  {
  title:"REAL ESTATE",
  desc:"HMG invests and develops various world-class facilities and services such as reports, hotels, multi-complex, land acquisition projects to introduce further iconic commercial and residential development projects, providing the same mix of innovative designs throughout Asia.",
  },
  {
    title:"INVESTMENT",
    desc:"HMG focuses on M&A deals under asset management and investment arm to broaden its investment coverage to encompass a wider spectrum of alternative investment including overseas real estate, retail FMCG, infrastructure, energy, logistic & transporation, media, IT, and hedge find.",
  }
]; 

class OurbusinessMobile extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
  render() {
    return (
      <>
        <section className="section-nucleo-icons" id="business_page">
          <Row className="align-items-center justify-content-center">
            <Col className="mt-5 text-center contener-content">
              <p>
                <span className="title-didot-black">OUR</span>
                <span className="title-didot-grey"> BUSINESS</span>
              </p>
            </Col>
          </Row>

          {/* F&B */}
          <Row className="row-grid align-items-top my-sm">
            <Col md="6">
            <Card className="bg-default border-0">
            <CardImg
            alt="..."
            src={require("assets/img/brand/main/ourbusiness_F&B.png")}
            />
            </Card>
            </Col>
            <Col md="6">
            <div className="spacing-vertical-mobile align-items-center">
                <div>
                    <p className="title-pluto" id="fb_page">{Body[0].title}</p>
                    <h6 className="description-Normal">
                        {Body[0].desc}
                    </h6>
                </div>
                <Button
                    className="btn-3"
                    color="primary"
                    type="button"
                    size="sm"
                    to="/foodbusiness-page"
                    tag={Link}
                >
                <span className="btn-inner--text h6-button">VIEW MORE</span>
                </Button>
            </div>
            </Col>
          </Row>

          {/* REAL ESTATE */}
          <Row className="row-grid align-items-top mb-md my-lg">
            <Col md="6">
              <Card className="bg-default border-0">
              <CardImg
              alt="..."
              src={require("assets/img/brand/main/ourbusiness_realestate.png")}
              />
              </Card>
            </Col>
            <Col md="6">
            <div className="spacing-vertical-mobile align-items-center">
                <div>
                    <p className="title-pluto" id="fb_page">{Body[1].title}</p>
                    <h6 className="description-Normal">
                        {Body[0].desc}
                    </h6>
                </div>
                <Button
                    className="btn-3"
                    color="primary"
                    type="button"
                    size="sm"
                    to="/realestate-page" 
                    tag={Link}
                >
                <span className="btn-inner--text h6-button">VIEW MORE</span>
                </Button>
            </div>
            </Col>
          </Row>

          {/* INVESMENT */}
          <Row className="row-grid align-items-top mb-md mt-lg">
              <Col md="6">
                <Card className="bg-default border-0">
                <CardImg
                alt="..."
                src={require("assets/img/brand/main/ourbusiness_investment.png")}
                />
                </Card>
              </Col>
              <Col md="6">
                <div className="spacing-vertical-mobile align-items-center">
                    <div>
                      <p className="title-pluto" id="fb_page">{Body[2].title}</p>
                      <h6 className="description-Normal">
                      {Body[2].desc}
                      </h6>
                    </div>
                    <Button
                      className="btn-3"
                      color="primary"
                      type="button"
                      size="sm"
                      to="/invesment-page"
                      tag={Link}
                    >
                      <span className="btn-inner--text h6-button">VIEW MORE</span>
                    </Button>
                  </div>
              </Col>
          </Row>
        </section>
      </>
    );
  }
}

export default OurbusinessMobile;
