import React from "react";
import { Container, Row, Col, Card, CardImg, CardImgOverlay } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/engineering.png",
    Title: "OUR BUSINESS",
    childTitle: "ENGINEERING <br/>TECHNOLOGY"
}

const title = "ENGINEERING TECHNOLOGY";

const content = [
    {
        value: `HMG’s Backbone Engineering Technology of Cable Network is operated by HMG’s subsidiary affiliate entity, HUMAN NETWORKS which is known as a leading backbone network and telecommunication cable provider in Korea with over 15 years of in-depth knowledge in cable network turn-key solution. HNK is a fiber optic cable manufacturer, cable installer and sole distributor of industrial cables in telecommunication, power & energy, marine to offshore cables with industry leader affiliates in each specialty fields. Our turnkey solution helps you to minimize investing extensive resources of your own in an ‘once in a life time’ project as we cover from cable network, system construction and site acquisition solution from the central office crossed over outside plant to premises globally. `
    },
    {
        value: ``
    },
    {
        value: ``
    },
    {
        value: ``
    }]

const link = [
    {
        value: 'TURN-KEY SOLUTION FOR CABLE NETWORK ENGINEERING & INFRASTRUCTURE CONSTRUCTION'
    },
    {
        value: 'SPECIALIZES IN BACKBONE NETWORK DESIGN AND CONSTRUCTION OF TELECOMMUNICATION CABLES'
    },
    {
        value: 'FIBER OPTIC CABLES AND CABLE EQUIPMENT INSTALLATION'
    },
    {
        value: 'TELECOMMUNIATION & ELECTRICITY POWER CABLE MANUFACTURER & DISTRIBUTOR'
    },
    {
        value: 'DATA CENTERS DESIGN & NETWORK INFRASTUCTURE'
    },
    {
        value: 'NETWORK DESIGN ; FTTx SOLUTION AND VOIP ACCESS NETWORK SOLUTION'
    },
    {
        value: 'SMART GRID ; OFFSHORE WIND POWER, SOLAR POWER'
    },
    {
        value: 'RENEWABLE ENERGY & INDUSTRIAL CABLE DISTRIBUTION'
    },
    {
        value: 'MARINE & OFFSHORE PLANTS CABLE PROVIDER ; POWER PLANTS AND BUILDING AND VESSEL CABLES'
    },
    {
        value: 'TECHNICAL SUPPORT: CONSULTANCY, DESIGN & ENGINEERING, MAINTENANCE, NETWORK OPERATION SUPPORT, TRAINING, TECHNOLOGY TRANSFER'
    },
]

const image =
{
    img1: "content/engineering8.png",
    img2: "content/engineering9.png",
}

const imgTitle = [
    {
      images: "content/engineeringa2-dark.png",
      title: "SMART GRID ; OFFSHORE WIND POWER, SOLAR POWER",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
    },
    {
      images: "content/engineeringa3-dark.png",
      title: "RENEWABLE ENERGY & INDUSTRIAL CABLE DISTRIBUTION",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
    },
    {
      images: "content/engineeringa1-dark.png",
      title: "TURN-KEY SOLUTION FOR CABLE NETWORK ENGINEERING & INFRASTRUCTURE CONSTRUCTION",
      content1: "",
      content2: "",
      content3: "",
      content4: "",
      content5: "",
    },
    
  ]

class Engineering extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    imageChange = () => {
        const isMobile = this.state.width <= 500;
        if (isMobile) {
            return (
                <Row className="justify-content-center">
                    {
                  imgTitle.map(value => {
                    return (
                      <Col className="mb-lg-0 px-0" md="4">
                          <Card inverse>
                            <CardImg src={value.images} alt="Card image cap" />
                            <CardImgOverlay className="vertical-center">
                              <Row>
                                <Col className="text-center">
                                  <p className="card-img-title">{value.title}</p>
                                  <p className="card-img-content">{value.content1}</p>
                                  <p className="card-img-content">{value.content2}</p>
                                  <p className="card-img-content">{value.content3}</p>
                                  <p className="card-img-content">{value.content4}</p>
                                  <p className="card-img-content">{value.content5}</p>
                                </Col>
                              </Row>
                            </CardImgOverlay>
                          </Card>
                      </Col>
                    );
                  })
                }
                </Row>
            );
        } else {
            return (
                <Container className="pb-md d-flex">
                    <Row className="justify-content-center">
                    {
                        imgTitle.map(value => {
                            return (
                            <Col className="mb-lg-0 px-0" md="4">
                                <Card inverse>
                                    <CardImg src={value.images} alt="Card image cap" />
                                    <CardImgOverlay className="vertical-center">
                                    <Row>
                                        <Col className="text-center">
                                        <p className="card-img-title">{value.title}</p>
                                        <p className="card-img-content">{value.content1}</p>
                                        <p className="card-img-content">{value.content2}</p>
                                        <p className="card-img-content">{value.content3}</p>
                                        <p className="card-img-content">{value.content4}</p>
                                        <p className="card-img-content">{value.content5}</p>
                                        </Col>
                                    </Row>
                                    </CardImgOverlay>
                                </Card>
                            </Col>
                            );
                        })
                    }
{/*                     
                    <Col className="mb-lg-0 px-0" md="4">
                        <CardImg src="content/engineeringa3.jpg" alt="Card image cap" /></Col>
                        <Col className="mb-lg-0 px-0" md="4">
                        <CardImg src="content/engineeringa1.jpg" alt="Card image cap" /></Col> */}
                </Row>
                </Container>
            );
        }
    }
    render() {
        const isMobile = this.state.width <= 500;
        let style;
        if (isMobile) {
            style = "py-sm section-components"
        } else {
            style = "section section-components"
        }
        return (
            <>
                <DemoNavbar />
                <main className="luxury" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className={style}>
                        <Container>
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">CABLE NETWORK </span><br /><span className="home-title-body-blue">ENGINEERING & INFRASTRUCTURE  </span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <p className="home-child-title-body" id="fb_page">
                                            {title}
                                        </p>
                                        {
                                            content.map(value => {
                                                return (
                                                    <p className="description-Normal">
                                                        {value.value}
                                                    </p>
                                                );
                                            })
                                        }
                                        <ul className="ul-create">
                                            {
                                                link.map(value => {
                                                    return (
                                                        <li className="link-li">{value.value}</li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section>
                </main>
                {this.imageChange()}
                <CardsFooter />
            </>
        );
    }
}

export default Engineering;