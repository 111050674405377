import React from "react";
import { Container, Row, Col, Card, CardImg } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/defense.png",
    Title: "OUR BUSINESS",
    childTitle: "DEFENSE <br/> TRADING"
}

const content1 = {
    title: "INTERNATIONAL DEFENSE TRADING",
    content1: `
    HMG is an international corporation dedicated to meeting your needs as a reliable defense
    contractor, providing the highest standard quality military equipment at competitive prices.`,

    content2: `As a professional services company solely focused on providing a full spectrum of defense
    trade policy, releasability, export controls and execution consulting services to the global
    defense industries. HMG offers subject matter expertise with holistic perspective on how to
    structure successful international programs due to prior leadership roles within both the
    Governments and Prime Defense Companies.`,

    content3: `Our mission is to facilitate international defense
    trading and advance the economic security of the defense industrial base while balancing
    national security & foreign policy interests.`
}

const content = [
    {
        title: "GOVERNMENT POLICY",
        content: `
        Government-to-government sales are a significant portion of the defense trade market. We
guide you through the security assistance & cooperation enterprise to acquire new business.
    `},
    {
        title: "RELEASABILITY",
        content: `
        Sensitive technology developed by each country’s defense industry is frequently restricted from
export. We offer exportability strategies for your products and facilitate release of advanced
technologies based on our trading expertises.
    `},
    {
        title: "EXPORT CONTROL",
        content: `
        Companies with agile compliance programs have a competitive advantage when conducting
international business. We navigate export control regulations in order to develop international
sales in compliance with the International Traffic in Arms Regulations (ITAR).
    `},
    {
        title: "MANAGEMENT & EXECUTION",
        content: `
        Successful capture planning and program management are essential to compete in the global
marketplace. We integrate complex international business requirements into strategies for
successful program implementation. We also break down internal roadblocks to help you create
efficiency within your organization’s international business.
    `}
]

class Defense extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const contactStyle = {
            background: "linear-gradient( rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0) ), url('content/defense1.png') no-repeat center center ",
            backgroundSize: "cover",
            height: "100%"
        };
        const isMobile = this.state.width <= 500;
        let style;
        if (isMobile) {
            style = "pt-sm section-components"
        } else {
            style = "section section-components"
        }
        return (
            <>
                <DemoNavbar />
                <main className="defense" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className={style}>
                        <Container>
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">DEFENSE </span><br /><span className="home-title-body-blue">TRADING</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <div>
                                            <p className="home-child-title-body" id="fb_page">
                                                {content1.title}
                                            </p>
                                            <p className="description-Normal">
                                                {content1.content1}
                                            </p>
                                            <p className="description-Normal">
                                                {content1.content2}
                                            </p>
                                            <p className="description-Normal">
                                                {content1.content3}
                                            </p>
                                        </div>
                                        {
                                            content.map((value, index) => {
                                                return (
                                                    <div>
                                                        <p className="home-child-title-body mt-5" id="fb_page">
                                                            {value.title}
                                                        </p>
                                                        <p className="description-Normal mt-4">
                                                            {value.content}
                                                        </p>
                                                    </div>
                                                );
                                            })
                                        }
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section>
                </main>
                <Container className="pb-md d-flex">
                    <Row>
                        <Col md="12">
                            <CardImg src="content/defense1.png" alt="Card image cap" />
                        </Col>
                    </Row>
                </Container>
                <CardsFooter />
            </>
        );
    }
}

export default Defense;