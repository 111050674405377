import React from "react";
import { Container, Row, Col, Card, CardImg } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/medical.png",
    Title: "OUR BUSINESS",
    childTitle: "MEDICAL <br/> SUPPLY <br/> TRADING",
}

const content = [{
    title: "CONNECTING MEDICAL INDUSTRIES & HEALTHCARE PROVIDERS ACROSS THE GLOBE",
    content1: `
    HM Global Holdings Pte. Ltd. connects manufacturers, buyers, and investors from South Asia to
    across the globe. Our HMG medical division supplies trusted, high quality and reliable PPE and
    medical equipments to countries around the world. As the current crisis accelerates, HMG is
    dedicated to assist health care providers and governments with cost-effective, FDA/CE certified,
    carry out ISO 9001 of high quality medical supplies.`,

    content2: `HMG connects buyers, governments and healthcare institutions from around the world with
    biotech and medical equipment suppliers based in Asia. We ensure the highest quality and
    reliable products only from certified manufacturers. We utilize our decades of experience of
    doing business in the region to source trustworthy and reliable medical supplies with our
    products range from PPE to test kits to surgical gowns, reliable tools that enable healthcare
    professionals to save lives.`,

    titleTWO: "PERSONAL PROTECTION EQUIPMENT (PPE)",
    content3: `
    Personal protective equipment (PPE) is used every day by healthcare personnel (HCP) to
    protect themselves, patients, and others when providing care. HMG offers PPE in the form of
    Isloation Suit, Isloation Gwon, N95 Mask, Nitrile Gloves and much more.
    `,
    titleTHREE: "PPE PRODUCT",
    contentTHREE: `
        
    `
}]

const link = [
    {
        value: `Disposable Powder Free Nitrile Patient Examination Glove<br/>(Top Glove, Hartalega, Mercator Mcare, Arista, Shamrock, Advance, Omni 212, Medisafe, Sri Trang)`,
        // valueMB: `Disposable Powder Free Nitrile Patient Examination Glove (Top Glove, Hartalega, Mercator Mcare, Arista, Shamrock, Advance, Omni 212, Medisafe, Sri Trang)`
    },
    {
        value: `Disposable Gloves (Latex/Synthetic Vinyl)`,
        valueMB: `Disposable Gloves<br/>(Latex/Synthetic Vinyl)`
    },
    {
        value: `Surgical Isolation Gown (FYC Level 1-4)`,
        valueMB: `Surgical Isolation Gown<br/>(FYC Level 1-4)`
    },
    {
        value: `Surgical Gown (FYA Level 3-4)`,
    },
    {
        value: `Medical Protective Suit`,
    },
    {
        value: `3M N95 Respirator`,
    },
    {
        value: `KN97 / KN95 / KN94 Masks`,
    },
    {
        value: `N95 Respirators`,
    },
    {
        value: `3ply or 4ply Surgical Masks`,
    },
    {
        value: `Silver Nano Technology Disinfectant / Sanitizer `,
    },
    {
        value: `COVID-19 Testing Kits`,
    }
]

const imagelogo = [
    {
        value: `content/medical_logo1.png`
    },
    {
        value: `content/medical_logo2.png`
    },
    {
        value: `content/medical_logo3.png`
    },
    {
        value: `content/medical_logo4.png`
    },
    {
        value: `content/medical_logo5.png`
    },
    {
        value: `content/medical_logo6.png`
    }
]

class Medical extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const isMobile = this.state.width <= 400;
        return (
            <>
                <DemoNavbar />
                <main className="medical" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className="section section-components">
                        <Container>
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">MEDICAL<br />SUPPLY </span><br /><span className="home-title-body-blue">TRADING</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <p className="home-child-title-body">
                                            {content[0].title}
                                        </p>
                                        <p className="description-Normal">
                                            {content[0].content1}
                                        </p>
                                        <div className="justify-content-between">
                                            <Row className="mt-4" md="12">
                                                {
                                                    imagelogo.map(value => {
                                                        return (
                                                            <Col>
                                                                <img style={{ height: 70 }} src={value.value} />
                                                            </Col>
                                                        );
                                                    })
                                                }
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                        <Container className="mt-5">
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">PPE <br /> PRODUCT</span><br /><span className="home-title-body-blue">LIST</span>
                                        <br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <p className="home-child-title-body">
                                            {content[0].titleTWO}
                                        </p>
                                        <p className="description-Normal">
                                            {content[0].content2}
                                        </p>
                                        <p className="home-child-title-body mt-sm">
                                            {content[0].titleTHREE}
                                        </p>
                                        <ul className="ul-create">
                                            {
                                                link.map(value => {
                                                    if (isMobile) {
                                                        if (value.valueMB) {
                                                            return (
                                                                <li className="link-li">{parse(value.valueMB)}</li>
                                                            );
                                                        } else {
                                                            return (
                                                                <li className="link-li">{parse(value.value)}</li>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <li className="link-li">{parse(value.value)}</li>
                                                        );
                                                    }
                                                })
                                            }
                                        </ul>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section>
                    {/* {this.imageChange()} */}
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default Medical;