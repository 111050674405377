/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from 'react-anchor-link-smooth-scroll'

// reactstrap components
import {
  Button,
  Card,
  CardImg,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class CardsFooter extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <section className="section-nucleo-icons" id="business_page">
            <Container>
              <Row className="row-grid align-items-top">
                <Col md="6">
                  <CardImg className="footer-logoimage" src='footerlogo.png'/>
                  <div className="mt-4">
                    <p className="text-white footer-text">Address: 10 Ubi Crescent, Ubi Techpark, Singapore 408564</p>
                    <p className="text-white footer-text">Email: info@hmssg.co</p>
                    <p className="text-white footer-text">HM GLOBAL HOLDINGS PTE. LTD. ALL RIGHTS RESERVED </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </footer>
      </>
    );
  }
}

export default CardsFooter;
