


import React from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import parse from "html-react-parser";
import DemoNavbar from "components/Navbars/DemoNavbar";

const text1 = "TRADING AND INVESTEMENT GROUP"
const text2 = "VIEWMORE";

class ChildPageBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  fontChange = () => {
    const isMobile = this.state.width <= 500;
    if (isMobile) {
      return (
        <Container className="shape-container d-flex align-items-center mt-7">
          <div className="col px-0 mx-lg-md">
            <Row>
              <Col lg="4">
                <p className="display-3">
                  <p className="banner-page-space text-white">{this.props.banner.Title}</p>
                  <p className="display-3 child-banner-white">{parse(this.props.banner.childTitle)}</p>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      );
    } else {
      return (
        <Container className="shape-container d-flex align-items-center py-md mt-xl">
          <div className="col px-0 mx-lg-md">
            <Row>
              <Col lg="4">
                <p className="display-3">
                  <p className="banner-page-space text-white">{this.props.banner.Title}</p>
                  <p className="display-3 child-banner-white pt-2">{parse(this.props.banner.childTitle)}</p>
                </p>
              </Col>
            </Row>
          </div>
        </Container>
      );
    }
  }
  render() {
    const storyStyle = {
      background: "linear-gradient( rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0) ), url('" + this.props.banner.background + "') no-repeat center center",
      backgroundSize: "cover"
    };
    return (
      <>
        <section
          className="section section-lg section-banner section-shaped"
          style={storyStyle}
        >
          {this.fontChange()}
        </section>
      </>
    );
  }
}

export default ChildPageBanner;
