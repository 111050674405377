import React from "react";
import { Container, Row, Col, Card, CardImg, CardImgOverlay } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
  background: "banner/food.png",
  Title: "OUR BUSINESS",
  childTitle: "FOOD & BEVERAGE<br/> DISTRIBUTION"
}

const content = [{
  title: "IMPORT & EXPORT DISTRIBUTION",
  content1: `HMG has been a leader providing unique alternatives in the industry through partnerships with famous international renowned consumer retail FMCG brand categories such as packaged foods, beverages, confectionery, alcohol, fresh and frozen poultry and seafood, fresh fruit, ready to eat, ready to cook, chef fine dining related products. In partnership with top global food companies in these categories, HMG has introduced a variety of top-quality premium international products to various distribution channels in Asia.`,
  content2: `HMG has been leading food and beverage distribution industry with differentiated products and innovative logistics systems where distribution sector will continue to push forward as an excellent global distribution company throughout Asia.`,

  content3: `HMG supply wide categories of food and beverage products with a strong sourcing network with global food companies for import & export products including agriculture, seafood, poultry, confectionery to packaged products. We hold widest distribution channels of import and export distributor and retailer network channels throughout Asia including retail supermarkets as well as department stores, convenience stores, and HORECA which covers international biggest retail channels of global products both as import and export trading and distribution from Asian countries.`,

  content4: `Considering the scale of the locations and the specific needs of the customer in the region, HMG organizes food and beverage merchandises that meet the high standards and competitive rates through strong partnerships of global brands.`,


},
{
  title: "PRIVATE BRANDS",
  content1: `Never than before, Korean wave is a huge global trend where K-Pop, K-Drama, K-Beauty and K-Food is greatly impacting the way Global audience see, follow, and purchase as a new trend to be considered. At HMG, we have seen this global trend of Korean wave now heavily shifting to K-Food due to appealing Korean <u>Hallyu</u> (globalized Korean pop culture) shown throughout social media and Netflix.`,

  content2: `The audiences are mesmerized of Korean culture and thus, start to eat, dress, and even try to look like Koreans - just like what you see on TV and social media. Especially, "mukbang", which is an eating show, is an online audiovisual broadcast where host consumes various quantities of food while interacting with the audience. The genre became popular in <u>South Korea</u> in 2010, and has since become a major contributor to K-Food, along with <u>K-Beauty</u>, <u>K-pop</u>, and <u>K-dramas</u>, earning and cementing its status as a global trend.`,

  content3: `From mukbang, #1 ranking K-Food globally is known as Korean “Ramyeon” and “Soju”. We have spent years of R&D to make the best Korean products for our global audience to provide the best quality, authentic taste of Korean instant noodle (Ramyeon) and have collaborated with Korea’s national premium alcohol drink (Andong Soju).`,

  content4: `Our own private brands of Korean instant noodled called "CINBOSS RAMYEON" and Korea's oldest history of "ANDONG SOJU" were born as a new leading Korean products to cater the cravenness of global audience at affordable pricing and authentic taste from Korea.`
}
]

const imgTitle = [
  {
    images: "content/food1.png",
    title: "PACKAGED PRODUCT",
    content1: "- FROZEN FOOD",
    content2: "- DRY FOOD",
    content3: "- READY TO EAT",
    content4: "- READY TO COOK",
    content5: "- CHEF'S MEAL",
  },
  {
    images: "content/food2.png",
    title: "HORECA",
    content1: "- HORECA CHANNELS (1,600+)",
    content2: "- FROZEN FOOD FOR HORECA",
    content3: "- SAUCE FOR HORECA",
    content4: "- FINE DINING INGREDIENTS",
    content5: "",
  },
  {
    images: "content/food3.png",
    title: "OEM /ODM MANUFACTURING",
    content1: "- OEM / ODM CHANNELS (+230 ENTERPRISE CLIENTS)",
    content2: "",
    content3: "",
    content4: "",
    content5: "",
  },
  {
    images: "content/food4.png",
    title: "GLOBAL SOURCING & DISTRIBUTION",
    content1: "- GLOBAL DISTRIBUTION CHANNELS",
    content2: "",
    content3: "",
    content4: "",
    content5: "",
  },
  {
    images: "content/food5.png",
    title: "PRIVATE BRANDS",
    content1: "- KR INSTANT NOODLE (CINBOSS RAMYEON)",
    content2: "- KOREAN PREMIUM ANDONG SOJU (ONJU)",
    content3: "",
    content4: "",
    content5: "",
  },
  {
    images: "content/food6.png",
    title: "AGRICULTURE, POULTRY & FISHERY",
    content1: "- GRAINS, PALM OIL",
    content2: "- POULTRY",
    content3: "- SEAFOOD",
    content4: "",
    content5: "",
  },
  {
    images: "content/food7.png",
    title: "BAR & RESTAURANT",
    content1: "- BARS & LOUNGES",
    content2: "- RESTAURANTS",
    content3: "",
    content4: "",
    content5: "",
  },
  {
    images: "content/food8.png",
    title: "FOOD TECHNOLOGY",
    content1: "- ADVANCED R&D TECHNOLOGY",
    content2: "- FOOD SECURITY",
    content3: "",
    content4: "",
    content5: "",
  },

]

class Food extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  styleChange = () => {
    const isMobile = this.state.width <= 500;
    if (isMobile) {
      return (
        <section className="pt-sm section-components">
          <Container>
            <section className="section-nucleo-icons">
              <Row className="row-grid align-items-top">
                <Col md="5">
                  <span className="home-title-body-black">FOOD & BEVERAGE</span><br /><span className="home-title-body-blue">DISTRIBUTION</span>
                  <br /><img src='underbar.png' />
                </Col>
                <Col md="7">
                  <div>
                    <p className="home-child-title-body" id="fb_page">
                      {content[0].title}
                    </p>
                    <p className="description-Normal">
                      {content[0].content1}
                    </p>
                    <p className="description-Normal">
                      {content[0].content2}
                    </p>
                    <p className="description-Normal">
                      {content[0].content3}
                    </p>
                    <p className="description-Normal">
                      {content[0].content4}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                {
                  imgTitle.map(value => {
                    return (
                      <Col className="mb-lg-0 px-0" md="12">
                          <Card inverse>
                            <CardImg src={value.images} alt="Card image cap" />
                            <CardImgOverlay className="vertical-center">
                              <Row>
                                <Col className="text-center">
                                  <p className="card-img-title">{value.title}</p>
                                  <p className="card-img-content">{value.content1}</p>
                                  <p className="card-img-content">{value.content2}</p>
                                  <p className="card-img-content">{value.content3}</p>
                                  <p className="card-img-content">{value.content4}</p>
                                  <p className="card-img-content">{value.content5}</p>
                                </Col>
                              </Row>
                            </CardImgOverlay>
                          </Card>
                      </Col>
                    );
                  })
                }
              </Row>
            </section>
          </Container>
        </section>
      );
    } else {
      return (
        <section className="section section-components">
          <Container>
            {/*Food Distribution*/}
            <section className="section-nucleo-icons" id="whoweare_page">
              <Row className="row-grid align-items-top">
                <Col md="5">
                  <span className="home-title-body-black">FOOD & BEVERAGE </span><br /><span className="home-title-body-blue">DISTRIBUTION</span>
                  <br /><br /><img src='underbar.png' />
                </Col>
                <Col md="7">
                  <div>
                    <p className="home-child-title-body" id="fb_page">
                      {parse(content[0].title)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[0].content1)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[0].content2)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[0].content3)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[0].content4)}
                    </p>
                  </div>
                  <div>
                    <p className="home-child-title-body" id="fb_page">
                      {parse(content[1].title)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[1].content1)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[1].content2)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[1].content3)}
                    </p>
                    <p className="description-Normal">
                      {parse(content[1].content4)}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-md">
                {
                  imgTitle.map(value => {
                    return (
                      <Col className="mb-lg-0 px-0" lg="3">
                        <div>
                          <Card inverse>
                            <CardImg src={value.images} alt="Card image cap" />
                            <CardImgOverlay className="vertical-center">
                              <Row>
                                <Col className="text-center">
                                  <p className="card-img-title">{value.title}</p>
                                  <p className="card-img-content">{value.content1}</p>
                                  <p className="card-img-content">{value.content2}</p>
                                  <p className="card-img-content">{value.content3}</p>
                                  <p className="card-img-content">{value.content4}</p>
                                  <p className="card-img-content">{value.content5}</p>
                                </Col>
                              </Row>
                            </CardImgOverlay>
                          </Card>
                        </div>
                      </Col>
                    );
                  })
                }
              </Row>


             

            </section>


            
          </Container>
        </section>
      );
    }
  }

  render() {
    return (
      <>
        <DemoNavbar />
        <main className="food" ref="main">
          <ChildPageBanner banner={banner} />
          {this.styleChange()}
        </main>
        <CardsFooter />
      </>
    );
  }
}

export default Food;