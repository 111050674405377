import React from "react";
import { Container, Row, Col, Card, CardImg } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/investment.png",
    Title: "OUR BUSINESS",
    childTitle: "DIVERSIFIED INVESTMENTS",
}

const content = [{
    title: "GLOBAL INVESTMENTS",
    content1: `Asia's booming economy has guaranteed a lucrative growth in the country, which in turn,
    tremendously boosted overall industries in its metropolitan cities. These Asian cities have
    implemented a number of strategic infrastructure and developmental projects to empower its
    economic trajectory, coupled with the new corporate privatization law implemented which allow
    foreigners to buy, stimulating an active growth in Asia's market. Our Investment Division has
    become a trustworthy arm that specializes in various financial investment services, including
    private equity, investment banking, merger & acquisitions, and asset management.`,

    content2: `Through an integrated approach across our firm, we seek to provide innovative financial
    solutions and investment vehicles to help our fund investors achieve their investment goals.
    With affiliates around the world, we fulfill our mission one investor at a time, one company at a
    time—and we do this as one global firm.`,

    contentTWO: `Our investment sector covers diversified portfolios of Private Equity, Next Generation
    Innovations such as Software, Security, Semiconductors, Consumer Electronics, Internet of
    Things (IoT), Information Services, Business services, Internet, Digital Media, Engineering
    Technology, Content and Communications.`,

    contentTWO2: `The Health Care Growth Sector includes Biopharmaceuticals, Medical Devices, Diagnostics, Life Science, Providers, HCIT/Informatics,
    and other Health & Medical related Services. The Consumer & Retail Sector covers FMCG,
    Retail, HORECA, and consumer goods & services areas. The Global Impact sector covers global challenges by the UN Sustainable Development Goals. 
    We also cover Real Estate including land acquisitions, hotels, resorts, retail, multi complex, 
    residential and commercial development projects. `,

    contentTWO3: `Energy sector includes oil & gas, renewable energy along with
    Infrastructure sector of Roads, Bridges, Railways, Airports, Seaports, Technology Data Center,
    and Communication Networks. Credit Investment Sector involves with Mezzanine Financing,
    Debt Financing, Merger & Acquisition, Structured Investments.`
}]

const link = [
    {
        value: '<b>Private Equity & Family Offices</b>'
    },
    {
        value: '<b>Next Generation Innovations:</b> Engineering Technology, Software, Security, Semiconductors, Consumer Electronics, IoT, Information Services, Digital Media, Content and Communications'
    },
    {
        value: '<b>Health Care Growth:</b> Biopharmaceuticals, Medical Devices, Diagnostics, Life Science, Health & Medical Services, Beauty & Cosmetics'
    },
    {
        value: '<b>Consumer & Retail:</b> FMCG, Retail, HORECA, Consumer Goods & Services'
    },
    {
        value: '<b>Global Impact:</b> Sustainable Development, ESG'
    },
    {
        value: '<b>Real Estate & Land Property Acquisition & Development</b>'
    },
    {
        value: '<b>Energy & Infrastructure:</b> Oil & Gas, Renewable Energy, Airports & Seaports, Roads and Bridges, Railways, Technology Data Centers, Communication Networks, Infrastructure Backbone Networks'
    },
    {
        value: '<b>Credit Financing:</b> Mezzanine Financing, Debt Financing, Merger & Acquisitions, Structured Investments'
    }
]

class Invesment extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };
    render() {
        const contactStyle = {
            background: "linear-gradient( rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0) ), url('content/investment1.png') no-repeat center center ",
        };
        const isMobile = this.state.width <= 500;
        let style;
        if (isMobile) {
            style = "pt-sm section-components"
        } else {
            style = "pt-md section-components"
        }
        return (
            <>
                <DemoNavbar />
                <main className="investment" id="investment" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className={style}>
                        <Container>
                            <section className="section-nucleo-icons" id="investment">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">DIVERSIFIED </span><br /><span className="home-title-body-blue">INVESTMENTS</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <p className="home-child-title-body" id="fb_page">
                                            {content[0].title}
                                        </p>
                                        <h6 className="description-Normal">
                                            {content[0].content1}
                                        </h6>
                                        <h6 className="description-Normal">
                                            {content[0].content2}
                                        </h6>
                                    </Col>
                                </Row>
                            </section>
                            <section className="section-nucleo-icons my-5" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">INVESTMENT</span><br /><span className="home-title-body-blue">SECTORS</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <ul className="ul-create">
                                            {
                                                link.map(value => {
                                                    return (
                                                        <li className="link-li">{parse(value.value)}</li>
                                                    );
                                                })
                                            }
                                        </ul>
                                        <p className="home-child-title-body" id="fb_page">
                                            {content[0].titleTWO}
                                        </p>
                                        <p className="description-Normal">
                                            {content[0].contentTWO}
                                        </p>
                                        <p className="description-Normal">
                                            {content[0].contentTWO2}
                                        </p>
                                        <p className="description-Normal">
                                            {content[0].contentTWO3}
                                        </p>
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default Invesment;