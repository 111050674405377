import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, HashRouter, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss";

import Index from "views/Index.jsx";
import Hero from "./views/IndexSections/PageBanner"
import Whoweare from "./views/IndexSections/Whoweare"
import Food from "views/pages/Food.jsx";
import Invesment from "views/pages/Invesment.jsx";
import Realestate from "views/pages/Realestate.jsx";
import ScrollToTop from "./views/IndexSections/ScroolToTop.jsx";
import Luxury from "views/pages/Luxury.jsx";
import Engineering from "views/pages/Engineering.jsx";
import Entertainment from "views/pages/Entertainment.jsx";
import Medical from "views/pages/Medical.jsx";
import Defense from "views/pages/Defense";
import CSR from "views/pages/CSR";
import ContactUS from "views/IndexSections/ContactUS";

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <Switch>
        <Route
          path="/"
          exact 
          render={props => <Index {...props} />}
        />
        <Route
          path="/food"
          exact
          render={props => <Food {...props} />}
        />
        <Route
          path="/luxury"
          exact
          render={props => <Luxury {...props} />}
        />
         <Route
          path="/engineering-technology"
          exact
          render={props => <Engineering {...props} />}
        />
        <Route
          path="/entertainment-agency"
          exact
          render={props => <Entertainment {...props} />}
        />

        <Route
          path="/medical"
          exact
          render={props => <Medical {...props} />}
        />
        <Route
          path="/defense"
          exact
          render={props => <Defense {...props} />}
        />
        <Route
          path="/investment"
          exact
          render={props => <Invesment {...props} />}
        />
        <Route
          path="/csr"
          exact
          render={props => <CSR {...props} />}
        />
        <Route
          path="/contact-us"
          exact
          render={props => <ContactUS {...props} />}
        />
        <Redirect to="/#" />
      </Switch>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById("root")
);
