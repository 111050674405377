import React from "react";
import { Container, Row, Col, Card, CardImg, CardImgOverlay } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/csr.png",
    Title: "OUR BUSINESS",
    childTitle: "CORPORATE<br/>SOCIAL RESPONSIBILITY",
}

const content1 =
{
    title: "CORPORATE SOCIAL RESPONSIBILITY (CSR)",
    content: `
    HMG Charitable Foundation creates programs that enhance entrepreneurial ecosystems. By
    leveraging the resources and intellectual capital, we empower entrepreneurs, create an
    environment where careers can flourish and support the communities in which we live and work.`
}

const content = [
    {
        title: "SUPPORTING COMMUNITIES",
        content: `
        At HMG, we have a proud tradition of serving our communities. We are uniquely positioned to
        make a positive impact through our investments and to strengthen the communities in which we
        live and work.
  `},
    {
        title: "CHANGING THE STATUS QUO",
        content: `
        We are always seeking a better approach – from better tools to better services – to the
        challenges that face our clients, our people and the communities we serve. A good company
        challenges the status quo; a great company changes it for the better.`
    },
    {
        title: "INTEGRATING ESG",
        content: `
        Environmental, Social and Governance principles have been integral HMG’s corporate strategy
        since our founding. We are committed to responsible investing practices and incorporate them
        into everything we do.`
    },
    {
        title: "EMPOWERING OUR PEOPLE",
        content: `
        It is not enough to help others in need. Social responsibility starts right at home with the
        thousands of people at HMG who are committed to giving back. That’s why we’re so dedicated
        to our people. The more we do for them, the more they can do for their communities.`
    },
    {
        title: "REDUCING ENVIRONMENTAL IMPACT",
        content: `
        We have a responsibility to leave the world a better place for future generations. Our approach
        to environmental sustainability focuses on measurable operational interventions to reduce
        resource usage across our portfolio and investments in large-scale renewable energy
        opportunities around the world.`
    }
]

const contentIalic = {
    content: `“We are committed to fostering innovative ideas, promoting healthy economies and enabling 
    social progress across the globe right now and for generations to come.”
    `
}

const imgTitle = [
    {
        images: "content/csr1.jpg",
        content: "CORPORATE SOCIAL<br/>RESPONSIBILITY (CSR)",
        contentMB: "CORPORATE SOCIAL RESPONSIBILITY (CSR)"
    },
    {
        images: "content/csr2.jpg",
        content: "SUPPORTING<br/>COMMUNITIES",
        contentMB: "SUPPORTING COMMUNITIES"
    },
    {
        images: "content/csr3.jpg",
        content: "CHANGING<br/>THE STATUS QUO",
        contentMB: "CHANGING THE STATUS QUO"
    },
    {
        images: "content/csr4.jpg",
        content: "EMPOWERING<br/>OUR PEOPLE",
        contentMB: "EMPOWERING OUR PEOPLE"
    },
]


class CSR extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const isMobile = this.state.width <= 500;
        return (
            <>
                <DemoNavbar />
                <main className="csr" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className="section section-components">
                        <Container>
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">CORPORATE <br />SOCIAL</span><br /><span className="home-title-body-blue">RESPONSIBILITY</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <div>
                                            <p className="home-child-title-body" id="fb_page">
                                                {content1.title}
                                            </p>
                                            <h6 className="description-Normal mt-4">
                                                {content1.content}
                                            </h6>
                                        </div>
                                        {
                                            content.map((value, index) => {
                                                return (
                                                    <div>
                                                        <p className="home-child-title-body mt-5" id="fb_page">
                                                            {value.title}
                                                        </p>
                                                        <h6 className="description-Normal mt-4">
                                                            {value.content}
                                                        </h6>
                                                    </div>
                                                );
                                            })
                                        }
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                        <Container className="container-lg mt-5">
                            <Row className="align-items-center justify-content-center">
                                <Col className="text-center">
                                    <p className="description-Italic">{contentIalic.content}</p>
                                </Col>
                            </Row>
                            <Row className="mt-md">
                                {
                                    imgTitle.map(value => {
                                        return (
                                            <Col className="mb-lg-0 px-1" lg="3">
                                                <div>
                                                    <Card inverse>
                                                        <CardImg src={value.images} alt="Card image cap" />
                                                        <CardImgOverlay className="vertical-center">
                                                            <Row>
                                                                <Col className="text-center">
                                                                    <p className="card-img-title">                    
                                                                        {isMobile ? parse(value.contentMB) : parse(value.content)}
                                                                    </p>
                                                                </Col>
                                                            </Row>
                                                        </CardImgOverlay>
                                                    </Card>
                                                </div>
                                            </Col>
                                        );
                                    })
                                }
                            </Row>
                        </Container>
                    </section>
                </main>
                <CardsFooter />
            </>
        );
    }
}

export default CSR;