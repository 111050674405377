import React from "react";
import { Container, Row, Col,Card,CardImg} from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";

const textBanner="REAL ESTATE"
const bodytext =      [{   
                      title:"LAND ACQUISITION",
                      desc1:"Our strategy is to acquire sites with good potential for future value enhancement through development density intensification, land usage conversion, as well as sites that will benefit from the Government's infrastructure and public transportation improvement programmes.",
                      desc2:"Over the year of local partnerships in different countries, we have made significant acquisitions of agricultural land with high development potential and proceeded to apply to the Goverment for land-use conversion of these land lots at a reasonable price together with local partners. We also acquire quality development sites through land auctions. public tenders and by private treaty.",
                      desc3:"The land acquisition provides an important and vital role for integral source of developable sites for the Group's ongoing project development portfolios in Asia",
                      },
                      
                      {
                      title:"HOTEL & RESORTS",
                      desc1:"Everyday life is filled with opportunities to seek the exceptional, encounter the new and widen our horizons. For our hotels and resort projects, we want everyone to feel one more chapter in a lifetime of travel and exploration and we intend to make it an extraordinary one.",
                      desc2:"HMG works with local partners on the most popular destinations of cosmopolitan cities, lush islands, desert sands, heritage destinations, uncharted beaches and contemporary resort destinations in Asia to build luxury properties. Our portfolio mainly focuses on South East Asia locations where the growth of luxury leisure is expanding substantially rapidly and widely.",
                      desc3:"Our thoughtfully designed luxury hotels and resorts will privide windows into the genuine modren character of each destination. Inside and outsite each property, travellers engage with the places, people and stories that make the destination like nowhere else in the world. Guests curate a lifetime of memories through personal experiences, opening dorrs and enables journeys of adventure and indulgence."
                    },
                    {
                      title:"TOURISM, LOGISTICS & INFRASTRUCTURE",
                      desc1:"Emerging touristic hot spots to both domestic and international destinations have increased the importance intourism, logistics and transportaion. Our vision is to enhance our core business values and to pledge societal contribution by improving regional and international connections worldwide.",
                      desc2:"Investing in these business areas is also a strategic move of our Group to expand existing system of exclusive tourism services, logistics and infrastructure developments, bringing the whole new level of infraeco-system which greatly impacts the overall economy growth and living stadnard of each country"
                    }
                  ]; 

class Realestate extends React.Component {
    render() {
      return (
        <>
        <DemoNavbar/>
        <main className="realestate" ref="main">
          <div className="position-relative">
            {/* Hero for FREE version */}
            <section className="section section-lg section-hero-realestate section-shaped">
              <Container className="shape-container d-flex align-items-center pt-xl mt-xl">
                  <div className="col">
                    <Row className="align-items-center justify-content-center">
                      <Col className="text-center contener-banner">
                          <p className="text-white didot-banner">{textBanner}</p>
                      </Col>
                    </Row>
                    <Row className="align-items-center justify-content-center mt-xl">
                    <Col className="text-center contener-banner">
                      <div className="nav-link">
                        <p className="text-white description-Italic"></p>
                      </div>
                    </Col>
                  </Row>
                  </div>
              </Container>
            </section>

            <section className="section section-components">
              <Container>
                <section>
                  <Container className="container-lg">
                    <Row className="align-items-center justify-content-center">
                      <Col className="mb-5 text-left contener-content">
                        <p><span className="title-didot-black">{bodytext[0].title}</span></p>
                        <h6 className="description-Italic">{bodytext[0].desc1}</h6>
                        <h6 className="description-Italic mt-sm">{bodytext[0].desc2}</h6>
                        <h6 className="description-Italic mt-sm">{bodytext[0].desc3}</h6>
                        <Card className="bg-default border-0 mt-sm">
                          <CardImg alt="error" src={require("assets/img/brand/realestate/realestate2.png")} top/>
                        </Card>
                      </Col>
                    </Row>
                    
                    <Row className="align-items-center justify-content-center">
                      <Col className="mb-5 text-left contener-content">
                        <p><span className="title-didot-black">{bodytext[1].title}</span></p>
                        <h6 className="description-Italic mt-sm">{bodytext[1].desc1}</h6>
                        <h6 className="description-Italic mt-sm">{bodytext[1].desc2}</h6>
                        <h6 className="description-Italic mt-sm">{bodytext[1].desc3}</h6>
                        <Card className="bg-default border-0 mt-sm">
                          <CardImg alt="..." src={require("assets/img/brand/realestate/realestate3.png")} top />
                        </Card>
                      </Col>
                    </Row>

                    <Row className="align-items-center justify-content-center">
                      <Col className="mb-5 text-left contener-content">
                        <p><span className="title-didot-black">{bodytext[2].title}</span></p>
                        <h6 className="description-Italic mt-sm">{bodytext[2].desc1}</h6>
                        <h6 className="description-Italic mt-sm">{bodytext[2].desc2}</h6>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </Container>
            </section>
          </div>
        </main>
        <CardsFooter/>
        </>
      );
    }
  }
  
  export default Realestate;