import React from "react";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter.jsx";

// index page sections
import Ourbusiness from "./IndexSections/Ourbusiness.jsx";
import OurbusinessMobile from "./IndexSections/OurbusinessMobile.jsx";
import Whoweare from "./IndexSections/Whoweare.jsx";
import WhoweareMobile from "./IndexSections/WhoweareMobile.jsx";
import PageBanner from "./IndexSections/PageBanner.jsx";
import Whatwefocus from "./IndexSections/Whatwefocus.jsx"
import ContactUS from "./IndexSections/ContactUS.jsx"
import Pills from "./IndexSections/Pills.jsx"

const banner =
{
  "Main": {
    background: 'banner/home.png',
    title: 'TRADING AND INVESTMENT GROUP',
  },

  "Whoweare": {
    background: '',
    title: '<p><span className="title-didot-black">WHO</span><span className="title-didot-grey"> WE ARE</span></p>',
    childTitle: '	INTERNATIONAL TRADING, ENGINEERING &<br>INVESTMENT COMPANY',
    content1: `HM Global Holdings Pte. Ltd. (HMG) is a global trading, engineering, and  investment company established with various business lines of Strategic Business Units, such as Import Export Distribution of Food & Beverage, Engineering & IT Technology in Cable Networks, Defense Arm of Military Products, and Fund Investments. Investment Energy sector includes oil & gas, renewable energy along with backbone Infrastructure sector of Power Plants, Buildings, Roads, Bridges, Railways, Airports, Seaports, Technology Data Center, and Telecommunication Cable Networks. Investment Sector involves with Mezzanine Project Financing, Debt Financing, Merger & Acquisition, Structured Investments.`,
    content2: `With our South East Asia's direct affiliate companies in Korea, Vietnam, and Indonesia, HMG has entered into strategic partnerships with a number of Government Ministries, National Level Enterprises, and Global Private Conglomerates in Asia as well as United States and Europe.`,
    content3: `HMG has outstanding professional teams with a complete supply chain system based on its worldwide networks. The company strives to grow into a horizontal integration for various import & export trading and diversified investments with strong global presence and competitive advantages.`
  },

  "OurBusiness": {
    background: 'content/home1.jpg',
    titleONE: 'OUR MISSION',
    contentONE: `Our mission is to operate the best general trades business from Asia-Pacific to global countries.
    We establish long lasting relationships with our clients by exceeding their expectations and
    gaining their trust through exceptional performance by every member of our team. Above all, we
    expect to surpass our clients expectations and add value to the communities we serve.`,
    titleTWO: 'OUR VISION',
    contentTWO: `Our vision is to become one of the top notch business legendries in Asia-Pacific in global
    trading and investment with strong networks combined with leanness, efficiency and agility of
    business capabilities. We want to continue our legacy as the most honest and ethical trade
    partner of choice and to be known as the premier trade partner that fosters an environment
    encouraging new ideas, new innovations and sustainable growth. We will earn our clients' trust
    and loyalty through continuous improvement driven by our core values.`
  },

  "Whatwefocus": [
    {
      titleONE: 'INTEGRITY',
      contentONE: `We venerate honesty and never compromise the truth. We will always do what is right and our
      business is based on trust. We adhere to the highest ethical standards and pledge to be
      transparent, just and consistent in word and deed.`,
      titleTWO: 'GRATITUDE',
      contentTWO: `We are truly thankful for the opportunities we have been given and believe this is the key to true greatness.
      Our positive minded team embraces gratitude as what gets poured into the glass to
      make it half full. Where there is gratitude, there is courtesy; there is a concern for the happiness
      and well-being of others.`,
    },
    {
      titleONE: 'EXCELLENCE',
      contentONE: `Our primary asset is people. We are a unified team working together to meet common goals.
      Our team is supportive of each other's efforts, loyal to one another and cares for each other
      both personally and professionally.`,
      titleTWO: 'TEAMWORK',
      contentTWO: `Our primary asset is people. We are a unified team working together to meet common goals.
      Our team is supportive of each other's efforts, loyal to one another and cares for each other
      both personally and professionally.`,
    },
    {
      titleONE: 'PASSION',
      contentONE: `We show pride, enthusiasm and dedication in everything we do. We are committed to delivering
      superior value to our clients.`
    }
  ],
  "ContactUS": {
    background: 'content/home2.png',
  },
};

class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <DemoNavbar mainScreen={true} />
        <main className="index" ref="main">
          <PageBanner banner={banner["Main"]} />
          <section>
            <Container>
              <Whoweare content={banner["Whoweare"]} />
            </Container>
            <Ourbusiness content={banner["OurBusiness"]} />
            <Container>
              <Whatwefocus content={banner["Whatwefocus"]} />
            </Container>
          </section>
        </main>
        <ContactUS content={banner["ContactUS"]} />
        <CardsFooter />
      </>
    );
    // }
  }
}

export default Index;
