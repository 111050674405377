import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  CardImg,
  Button,
  Container,
  CardImgOverlay
} from "reactstrap";

const Body = [{
  title: "F&B",
  desc: "HMG has grown to become the leading food business leader for a diverse range of food markets, such as food ingredients, processed food, private labeled food, bakery, confectionery, beverages, and even dining businesses."
}];

class Ourbusiness extends React.Component {
  constructor() {
    super();
    this.state = {
      width: window.innerWidth,
    };
  }
  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  styleChange = () => {
    const isMobile = this.state.width <= 500;
    if (isMobile) {
      return (
        <Container className="py-lg-md d-flex">
          <div className="col px-0 mx-lg-md">
            <Row className="row-grid align-items-top my-sm justify-content-between">
              <Col md="5">
                <div className="contener-content">
                  <p className="home-title-body-white" id="fb_page">{this.props.content.titleONE}</p>
                  <h6 className="description-Normal text-white">
                    {this.props.content.contentONE}
                  </h6>
                </div>
              </Col>
              <Col md="5">
                <div className="contener-content">
                  <p className="home-title-body-white" id="fb_page">{this.props.content.titleTWO}</p>
                  <h6 className="description-Normal text-white">
                    {this.props.content.contentTWO}
                  </h6>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      );
    } else {
      return (
        <Container className="py-lg-md d-flex">
          <div className="col px-0 mx-lg-md">
            <Row className="row-grid align-items-top my-sm justify-content-between">
              <Col md="5" className="pt-5">
                <div className="contener-content">
                  <p className="home-title-body-white" id="fb_page">{this.props.content.titleONE}</p>
                  <h6 className="description-Normal text-white">
                    {this.props.content.contentONE}
                  </h6>
                </div>
              </Col>
              <Col md="2">
                <div className="vertical-line" />
              </Col>
              <Col md="5" className="pt-5">
                <div className="contener-content">
                  <p className="home-title-body-white" id="fb_page">{this.props.content.titleTWO}</p>
                  <h6 className="description-Normal text-white">
                    {this.props.content.contentTWO}
                  </h6>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      );
    }
  }

  test = () => {
    return (
      <Card inverse>
        <CardImg src={this.props.content.background} alt="Card image cap" />
        <CardImgOverlay className="vertical-center">
          <Container className="py-lg-md d-flex">
            <div className="col px-0 mx-lg-md">
              <Row>
                <Col md="5">
                  <div className="contener-content">
                    <p className="home-title-body-white">{this.props.content.titleONE}</p>
                    <h6 className="description-Normal text-white">
                      {this.props.content.contentONE}
                    </h6>
                  </div>
                </Col>
                <Col md="2">
                  <div className="vertical-line" />
                </Col>
                <Col md="5">
                  <div className="contener-content">
                    <p className="home-title-body-white">{this.props.content.titleTWO}</p>
                    <h6 className="description-Normal text-white">
                      {this.props.content.contentTWO}
                    </h6>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </CardImgOverlay>
      </Card>
    );
  }


  // <Card inverse>
  //         <CardImg src={this.props.content.background} alt="Card image cap" />
  //         <CardImgOverlay className="vertical-center">
  //           <Container className="py-lg-md d-flex">
  //            <div className="col px-0 mx-lg-md">
  //             <Row>
  //               <Col md="5">
  //                 <div className="contener-content">
  //                   <p className="home-title-body-white">{this.props.content.titleONE}</p>
  //                   <h6 className="description-Normal text-white">
  //                     {this.props.content.contentONE}
  //                   </h6>
  //                 </div>
  //               </Col>
  //               <Col md="2">
  //                 <div className="vertical-line" />
  //               </Col>
  //               <Col md="5">
  //                 <div className="contener-content">
  //                   <p className="home-title-body-white">{this.props.content.titleTWO}</p>
  //                   <h6 className="description-Normal text-white">
  //                     {this.props.content.contentTWO}
  //                   </h6>
  //                 </div>
  //               </Col>
  //             </Row>
  //             </div>
  //           </Container>
  //         </CardImgOverlay>
  //       </Card>
  render() {
    const storyStyle = {
      background: "linear-gradient( rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0) ), url('" + this.props.content.background + "') no-repeat center center",
      backgroundSize: "cover"
    };
    return (
      <>
        <section
          className="section section-lg section-ourbusiness section-shaped"
          style={storyStyle}
        >
          {this.styleChange()}
        </section>
      </>
    );
  }
}

export default Ourbusiness;
