import React from "react";
// JavaScript plugin that hides or shows a component based on your scroll
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HashLink as Link } from 'react-router-hash-link';
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  NavbarBrand,
} from "reactstrap";
import NavbarBrandCreate from "assets/js/NarbarBrandCreate";

const aboutus = [
  {
    id: "0",
    title: "WHO WE ARE",
  },
  {
    id: "1",
    title: "OUR BUSINESS"
  },
  {
    id: "2",
    title: "INVESTMENT"
  },
  {
    id: "3",
    title: "CSR"
  },
  {
    id: "4",
    title: "CONTACT US"
  }
]

class DemoNavbarTL extends React.Component {
  linkWhoweare = () => {
    if (this.props.mainScreen == true) {
      return (
        <AnchorLink className="nav-link" href="#whoweare">
          <span className="nav-inner--text h6headroom">{aboutus[0].title}</span>
        </AnchorLink>
      )
    } else {
      return (
        <Link
          smooth
          className="nav-link"
          to="/#whoweare"
        >
          <span className="nav-inner--text h6headroom">{aboutus[0].title}</span>
        </Link>
      )
    }
  }

  linkContactus = () => {
    if (this.props.mainScreen == true) {
      return (
        <AnchorLink className="nav-link" href="#contact-us">
          <span className="nav-inner--text h6headroom">{aboutus[4].title}</span>
        </AnchorLink>
      )
    } else {
      return (
        <Link
          smooth
          className="nav-link"
          to="/#contact-us"
        >
          <span className="nav-inner--text h6headroom">{aboutus[4].title}</span>
        </Link>
      )
    }
  }

  render() {
    return (
      <>
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container className="justify-content-between">
            <NavbarBrand tag={Link} to="/">
              <div className="logoimage"/>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon-create"/>
            </button>

            <UncontrolledCollapse navbar toggler="#navbar_global">
              <Nav className="navbar-nav-hover align-items-lg-center ml-lg-auto navbar-nav" navbar>
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6" />
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                {this.linkWhoweare()}
                <UncontrolledDropdown nav>
                  <DropdownToggle nav>
                    <span className="nav-inner--text h6headroom">
                      {aboutus[1].title}
                    </span>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem to="/food" tag={Link} className="h6-dropdown-item">
                      FOOD & BEVERAGE
                    </DropdownItem>
                    <DropdownItem to="/engineering-technology" tag={Link} className="h6-dropdown-item" >
                      ENGINEERING TECHNOLOGY
                    </DropdownItem>
                    <DropdownItem to="/entertainment-agency" tag={Link} className="h6-dropdown-item" >
                      ENTERTAINMENT AGENCY
                    </DropdownItem>
                    {/* <DropdownItem to="/medical" tag={Link} className="h6-dropdown-item" >
                      MEDICAL SUPPLY
                    </DropdownItem> */}
                    <DropdownItem to="/luxury" tag={Link} className="h6-dropdown-item">
                      LUXURY & BEAUTY
                    </DropdownItem>
                    <DropdownItem to="/defense" tag={Link} className="h6-dropdown-item" >
                      DEFENSE
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <Link className="nav-link" to="/investment">
                  <span className="nav-inner--text h6headroom">{aboutus[2].title}</span>
                </Link>
                <Link className="nav-link" to="/csr">
                  <span className="nav-inner--text h6headroom">{aboutus[3].title}</span>
                </Link>
                {this.linkContactus()}
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default DemoNavbarTL;
