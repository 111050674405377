import React from "react";
import { Container, Row, Col, Card, CardImg } from "reactstrap";
import UncontrolledCarouselCreate from "assets/js/UncontrolledCarouselCreate"
import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import CardsFooter from "components/Footers/CardsFooter";
import ChildPageBanner from "./layout/ChildPageBanner";
import parse from "html-react-parser";

const banner = {
    background: "banner/celebrity.png",
    Title: "OUR BUSINESS",
    childTitle: "ENTERTAINMENT <br> & TALENT MANAGEMENT"
}

const title = "OFFICIAL ENTERTAINMENT AGENCY";

const content = [
    {
        value: `HMG is an official representing agency for overseas markets partnered with top Korean entertainment and talent agencies. We connect our world famous known Korean celebrities with companies of all sizes, from talent-led start-ups to Fortune 100 brands, to build and expand their businesses from forging partnerships across entertainment and media to providing strategic consulting to deepening connections with consumers. We also focus on developing in-house talents by selecting, growing, and managing ‘next-to-be star’ artist with advanced training supports by top professional teams from Korea and overseas.`
    },
    {
        value: `We help our clients entertain, inform, and inspire the world by representing and advancing global superstars who shape our culture and drive the future.  From world famous known Korean artists to influencers, our deep expertise and broad capabilities enable talents and companies to confidently grow their careers and businesses together. As a full-service global agency, we connect clients to opportunities across entertainment, media, and business and represent voices and vision define culture and shape our world. HMG represents some of the most watched and celebrated Korean actors and singers in film and television media. We connect our clients to their dream projects and partner with them to expand their opportunities across the cultural landscape of Korean entertainment.`
    },
    {
        value: ``
    },
    {
        value: ``
    }]

const link = [
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
    {
        value: ''
    },
]

const image =
{
    img1: "content/engineering8.png",
    img2: "content/engineering9.png",
}


class Entertainment extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth,
        };
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    imageChange = () => {
        const isMobile = this.state.width <= 500;
        if (isMobile) {
            return (
                <Row className="justify-content-center">
                    <Col className="mb-lg-0 px-0" md="12">
                        <CardImg src="content/cele2.jpg" alt="Card image cap" /></Col>
                    <Col className="mb-lg-0 px-0" md="12">
                        <CardImg src="content/cele3.jpg" alt="Card image cap" /></Col>
                </Row>
            );
        } else {
            return (
                <Container className="pb-md d-flex">
                    <Row className="justify-content-center">
                    <Col className="mb-lg-0 px-0" md="4">
                        <CardImg src="content/cele1.jpg" alt="Card image cap" /></Col>
                    <Col className="mb-lg-0 px-0" md="4">
                        <CardImg src="content/cele2.jpg" alt="Card image cap" /></Col>
                        <Col className="mb-lg-0 px-0" md="4">
                        <CardImg src="content/cele3.jpg" alt="Card image cap" /></Col>
                    </Row>
                </Container>
            );
        }
    }
    render() {
        const isMobile = this.state.width <= 500;
        let style;
        if (isMobile) {
            style = "py-sm section-components"
        } else {
            style = "section section-components"
        }
        return (
            <>
                <DemoNavbar />
                <main className="luxury" ref="main">
                    <ChildPageBanner banner={banner} />
                    <section className={style}>
                        <Container>
                            <section className="section-nucleo-icons" id="whoweare_page">
                                <Row className="row-grid align-items-top">
                                    <Col md="5">
                                        <span className="home-title-body-black">ENTERTAINMENT  </span><br/><span className="home-title-body-blue">& TALENT MANAGEMENT</span>
                                        <br /><br /><img src='underbar.png' />
                                    </Col>
                                    <Col md="7">
                                        <p className="home-child-title-body" id="fb_page">
                                            {title}
                                        </p>
                                        {
                                            content.map(value => {
                                                return (
                                                    <p className="description-Normal">
                                                        {value.value}
                                                    </p>
                                                );
                                            })
                                        }
                                        {/* <ul className="ul-create">
                                            {
                                                link.map(value => {
                                                    return (
                                                        <li className="link-li">{value.value}</li>
                                                    );
                                                })
                                            }
                                        </ul> */}
                                    </Col>
                                </Row>
                            </section>
                        </Container>
                    </section>
                </main>
                {this.imageChange()}
                <CardsFooter />
            </>
        );
    }
}

export default Entertainment;